.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: transparent;
  border-radius: 40px;
  width: 150px;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 8px ;
  padding: 8px;
}

.button__bar li button {
  border: none;
  background: #EEEEEE;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background-color: #e9be5a;
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: #e9be5a;
  outline: 0;
}

.slick-slide img {
  
  display: block;
  margin: auto;
}


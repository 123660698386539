
body {
  /* font-family:  'Montserrat'; */
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/**  custom scrollbar **/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #999;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #999;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}


.Card_card__48SLx{
  padding: 0px !important;
}

@media screen and (max-width: 760px) {
  .css-1qzevvg{
    margin: 0px !important;
  }
}